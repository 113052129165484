import { api, createCrudService } from '@cargill/shared'

const service = createCrudService(
  '/api/controltower/takeUpQualityAndContaminationTranslation',
  api,
  {
    importLogUrl: '/api/controltower/importLog',
    masterName: 'qualityContaminationId'
  }
)

export default service
